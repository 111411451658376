<template>
  <div class="follow-detail">
    <navbar title="跟进" />
    <div class="detail-box">
      <div class="detail-msg detail-item">
        <div class="msg-box">
          <div class="msg-name">{{ name }}</div>
          <div class="msg-phone">{{ phone | phoneFilter }}</div>
        </div>
        <div class="contact" @click="callPhone">联系他</div>
      </div>
      <div class="detail-item" @click="openPopup">
        <van-field v-model="followState" label="请修改客户跟进状态" right-icon="arrow-down" input-align="right" placeholder="选择修改" readonly />
      </div>
      <div class="detail-area detail-item">
        <van-field v-model="journal" rows="5" autosize label="跟进日志" type="textarea" placeholder="请输入跟进日志" maxlength="500" @change="check_num"/>
      </div>
      <div class="detail-record">
        <div class="record-title detail-item">跟进记录</div>
        <div class="record-item detail-item" v-for="item in recordList" :key="item.id">
          <div class="record-top">
            <div class="record-time">{{getTimes.timestampToTime(item.createTime)}}</div>
            <div class="record-type">{{ item.flowStatus }}</div>
          </div>
          <div class="record-content">
            <div class="content-text">日志：</div>
            <div class="content-msg">{{ escapeHtml(item.content) }}</div>
          </div>
        </div>
      </div>
      <div class="detail-btn" @click="getSubmit">提交</div>
    </div>
    <popup :showPopup="showPopup" :columns="stateList" @closePopup="closePopup" @checkPopup="getValue" />
  </div>
</template>
<script>
import navbar from "../../components/NavBar.vue";
import popup from "../../components/Popup.vue";
import {symbolEscape,escapeHtml } from "../../common/validate";

export default {
  name: "followDetail",
  data() {
    
    return {
      id: 0,
      name: "张三",
      phone: 18888888888,
      followState: "",
      journal: "",
      showPopup: false, //是否弹窗
      recordList: [
      ],
      stateList: [
        // { values: ["所有状态", "预约看房", "初次看房", "二次回访", "确定意向"] },
      ]
    };
  },
  created() {
    this.id = this.$route.query.id;
    console.log("zzzzzzz" + this.id);
  },
  components: {
    navbar,
    popup,
  },

  mounted() {
    this.getFollowDetail();
  },

  methods: {
    escapeHtml,
    check_num(){
      var regStr = /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/ig;
      if(regStr.test(this.journal)){
             this.journal = this.journal.replace(regStr,"")
       }
    },
    getFollowDetail() {
      const data = {
        id: this.id
      }
      this.$post("user/getFollowDetail", data).then((res) => {
        if (res.status == 200) {
          console.log(res.data.list);
          this.name = res.data.userBook.realName
          this.phone = res.data.userBook.mobile
          this.recordList = res.data.list
          this.stateList = res.data.tags
        }
      })
    },

    getSubmit() {
      if (!this.followState) { 
        this.$toast.fail("请选择跟进状态");
        return false;
      }
      const data = {
        id: this.id,
        followState: this.followState,
        journal: symbolEscape(this.journal)
      }
      this.$post("user/addToFollowUp", data).then((res) => {
        if (res.status == 200) {
          this.$router.push("/my/followUp");
        }
      })
    },

    openPopup() {
      this.showPopup = true
    },
    //关闭弹窗
    closePopup() {
      this.showPopup = false;
    },
    // 获取弹窗的值
    getValue(checkName) {
      this.followState = checkName;
      this.showPopup = false;
    },
    callPhone() {
      window.location.href = `tel://${this.phone}`
    }
  },
};
</script>
<style lang="less" scoped>
.follow-detail {
  padding-bottom: 45px;
  div {
    line-height: 1;
  }
  .detail-item {
    padding: 0 15px;
    background: #fff;
    height: 60px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    /deep/.van-cell {
      padding: 0;
      align-items: center;
      .van-field__label {
        width: auto;
        color: #545454;
        font-size: 15px;
      }
      input::-webkit-input-placeholder {
        color: #999;
      }
    }
  }
  .detail-msg {
    margin-bottom: 10px;
    align-items: center;
    border-bottom: 0;

    .msg-box {
      display: flex;
      align-items: center;
      .msg-name {
        font-size: 15px;
        color: #333;
        margin-right: 10px;
      }
      .msg-phone {
        font-size: 14px;
        color: #878787;
      }
    }
    .contact {
      height: 30px;
      line-height: 30px;
      padding: 0 15px;
      border-radius: 15px;
      background-color: #def;
      color: #007fff;
      font-size: 14px;
    }
  }
  .detail-area {
    margin-bottom: 10px;
    border-bottom: 0;
    height: unset;
    padding: 15px;
    /deep/.van-cell {
      align-items: flex-start;
      .van-cell__value {
        border: 1px solid #bbb;
        padding: 5px;
      }
    }
  }
  .detail-record {
    color: #545454;
    font-size: 15px;
    .record-title {
      align-items: center;
    }
    .record-item {
      height: unset;
      padding: 10px 15px;
      flex-direction: column;
      .record-top {
        margin-bottom: 20px;
        color: #858585;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .record-content {
        display: flex;
        align-items: flex-start;
        div {
          line-height: 20px;
        }

        .content-text {
          flex-shrink: 0;
        }
      }
    }
  }
  .detail-btn {
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: #00d3c4;
    font-size: 15px;
  }
}
</style>