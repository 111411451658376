<template>
  <div class="popup">
    <van-popup v-model="showPopup" position="bottom" @close="closePopup" :style="{ height: '50%' }">
      <van-picker show-toolbar :columns="columns"  @cancel="closePopup"  @confirm="onConfirm" />
    </van-popup>
  </div>
</template>
<script>
export default {
  name: "popup",
  props:{
      showPopup:'',
      columns:{
          trpe:Array,
          default:[]
      }
  },
  data(){
      return{
          value:""
      }
  },
  created(){
     
  },
  methods:{
      closePopup(){
        this.$emit('closePopup')
      },
      onConfirm(value){
        this.$emit('checkPopup',value)
      }
  }
};
</script>
<style lang="less" scoped>
</style>